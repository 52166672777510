import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../../assets/Header.css';

const Header = () => {

    const currentYear = new Date().getFullYear();

    const [apiResponse, setFuelPrice] = useState(null)

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0'); // Pad single digit day with 0
    const currentDate = `${year}${month}${day}`; // Correct string concatenation
  
    var currentURLWithoutPath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
    
    if(currentURLWithoutPath.includes("bitcare.me"))
    {
      currentURLWithoutPath += "/hargapetrol";
    }
    
    useEffect(() => {
      fetch(currentURLWithoutPath+`/data/${currentDate}.json`) // Assuming your .json file is in the public directory
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Convert the response to JSON
        })
        .then(data => setFuelPrice(data)) // Set the fetched data to state
        .catch(error => console.error('Error fetching fuel price:', error));
    }, [currentDate,currentURLWithoutPath]); // Include currentDate as a dependency
  
  
    if (!apiResponse) {
      return <div></div>; // Optionally, show a loading state
    }
    const fuelPrice = apiResponse.LastFuelPrice.original;

    
    return (
      <div className="row">
      <meta name="author" content="PetrolPrice"/>
      <meta
        name="description"
        content={`Malaysian petrol prices for the period ${fuelPrice.date} to ${fuelPrice.date_to} include RON95 (RM${fuelPrice.ron95}), RON97 (RM${fuelPrice.ron97}), Diesel Subsidy (RM${fuelPrice.diesel}), and Diesel (RM${fuelPrice.diesel_eastmsia}) announced by the Malaysian Ministry of Finance (MOF) and KPDNHEP.`}/>
      <meta
        name="keywords"
        content="malaysia petrol prices, latest petrol price in malaysia, diesel price in malaysia, kpdnkk announcement of petrol price, subsidy diesel price,
        weekly petrol price adjustment in malaysia, ron 95 malaysia, ron 97 malaysia, diesel malaysia"/>
        <div className="col-lg-12">
            <title>{`${currentYear} LATEST: Weekly Petrol Price in Malaysia`}</title>
            <nav className="topnav navbar navbar-expand-lg navbar-light bg-white fixed-top">
              <div className="container">
                <Link className="navbar-brand" to="/en/home">
                  <strong>PetrolPrice</strong>
                </Link>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarColor02"
                  aria-controls="navbarColor02"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"/>
                </button>
                <div className="navbar-collapse collapse" id="navbarColor02" style={{}}>
                  <ul className="navbar-nav mr-auto d-flex align-items-center">
                    <li className="nav-item">
                      <Link className="nav-link" to="/en/">Home
                        <span className="sr-only">(current)</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/en/articles">News & Articles</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/en/about">About</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/en/terms">Term of Use</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/en/privacy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
        </div>
      </div>
    );
};

export default Header;
