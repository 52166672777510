import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Articles from './components/Articles';
import About from './components/About';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import News from './components/News';

import HeaderEN from './components/en/Header';
import BodyEN from './components/en/Body';
import ArticlesEN from './components/en/Articles';
import AboutEN from './components/en/About';
import TermsEN from './components/en/Terms';
import PrivacyEN from './components/en/Privacy';
import FooterEN from './components/en/Footer';
import SidebarEN from './components/en/Sidebar';

function CanonicalUrl() {
  const location = useLocation();
  useEffect(() => {
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.setAttribute("href", window.location.href);
    } else {
      const link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      link.setAttribute("href", window.location.href);
      document.head.appendChild(link);
    }
  }, [location]);

  return null;
}

function HeaderSelector() {
  const location = useLocation();
  const isEnglishPath = location.pathname.startsWith('/en');
  return isEnglishPath ? <HeaderEN /> : <Header />;
}

function SidebarSelector() {
  const location = useLocation();
  const isEnglishPath = location.pathname.startsWith('/en');
  return isEnglishPath ? <SidebarEN /> : <Sidebar />;
}

function FooterSelector() {
  const location = useLocation();
  const isEnglishPath = location.pathname.startsWith('/en');
  return isEnglishPath ? <FooterEN /> : <Footer />;
}

function DomainRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const currentDomain = window.location.hostname;

    // Only redirect if we're not already in the /en/ path and haven't redirected yet
    if (currentDomain.includes('petrolprice') && !location.pathname.startsWith('/en') && !redirected){
      setRedirected(true); // Prevent further redirects
      navigate('/en' + location.pathname, { replace: true }); // Redirect to the /en/ version of the current path
    }
  }, [navigate, location, redirected]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="container pt-4 pb-4">

      <CanonicalUrl /> {/* Call the CanonicalUrl component to set the canonical URL */}
      <DomainRedirect /> {/* Redirect based on domain */}
      <HeaderSelector />

      <div className="row">
          <div className="col-lg-8" style={{ paddingBottom: 25 }}>
            <Routes>
              <Route path="/" element={<Body />} />
              <Route path="/harga-minyak-petrol-terkini" element={<Body />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/news/:title" element={<News />} />

              <Route path="/en/*" element={<BodyEN />} />
              <Route path="/en/articles" element={<ArticlesEN />} />
              <Route path="/en/about" element={<AboutEN />} />
              <Route path="/en/terms" element={<TermsEN />} />
              <Route path="/en/privacy" element={<PrivacyEN />} />
              <Route path="/en/news/:title" element={<News />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
          <div className="col-lg-4">
            <SidebarSelector />
          </div>
      </div>
      <FooterSelector />
      </div>

    </Router>
  );
}

export default App;
