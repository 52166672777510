import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'; // Import Link from react-router-dom
import '../assets/Header.css';

const Header = () => {

  const currentYear = new Date().getFullYear();

  const [apiResponse, setFuelPrice] = useState(null)

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(today.getDate()).padStart(2, '0'); // Pad single digit day with 0
  const currentDate = `${year}${month}${day}`; // Correct string concatenation

  var currentURLWithoutPath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

  if(currentURLWithoutPath.includes("bitcare.me"))
  {
    currentURLWithoutPath += "/hargapetrol";
  }

  useEffect(() => {
    fetch(currentURLWithoutPath+`/data/${currentDate}.json`) // Assuming your .json file is in the public directory
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Convert the response to JSON
      })
      .then(data => setFuelPrice(data)) // Set the fetched data to state
      .catch(error => console.error('Error fetching fuel price:', error));
  }, [currentDate,currentURLWithoutPath]); // Include currentDate as a dependency


  if (!apiResponse) {
    return; // Optionally, show a loading state
  }
  const fuelPrice = apiResponse.LastFuelPrice.original;

  return (
    <div className="row">
      <meta name="author" content="HargaPetrol"/>
      <meta
        name="description"
        content={`Harga petrol Malaysia bagi tempoh ${fuelPrice.date} hingga ${fuelPrice.date_to} meliputi RON95 (RM${fuelPrice.ron95}), RON97 (RM${fuelPrice.ron97}), Diesel Subsidi (RM${fuelPrice.diesel}), dan Diesel (RM${fuelPrice.diesel_eastmsia}) yang diumumkan oleh Kementerian Kewangan Malaysia (MOF) dan KPDNHEP.`}/>
      <div className="col-lg-12">
        <title>{`${currentYear} TERKINI: Harga Minyak Petrol Mingguan di Malaysia`}</title>
        <nav className="topnav navbar navbar-expand-lg navbar-light bg-white fixed-top">
          <div className="container">
            <Link className="navbar-brand" to="/harga-minyak-petrol-terkini">
              <strong>HargaPetrol</strong>
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarColor02"
              aria-controls="navbarColor02"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="navbar-collapse collapse" id="navbarColor02" style={{}}>
              <ul className="navbar-nav mr-auto d-flex align-items-center">
                <li className="nav-item">
                  <Link className="nav-link" to="/harga-minyak-petrol-terkini">Utama
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/articles">Berita & Artikel</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">Tentang Kami</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/terms">Syarat Penggunaan</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/privacy">Dasar Privasi</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
