import React, {useState, useEffect} from 'react'
// import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGasPump, faLeftRight, faUpLong, faDownLong} from '@fortawesome/free-solid-svg-icons'
import ChartPrice from './ChartPrice'
import FAQ from './FAQ'
import styled from "styled-components";

function arrowDisplay(currentPrice, previousPrice) {
  const priceStatus = currentPrice - previousPrice
    let icon,
      color

    if (priceStatus === 0) {
      icon = faLeftRight
      color = 'grey'
    } else if (priceStatus > 0) {
      icon = faUpLong
      color = 'red'
    } else {
      icon = faDownLong
      color = 'green'
    }
    return {icon, color}
  }

  function textDisplay(currentPrice, previousPrice) {
    const priceStatus = currentPrice - previousPrice
    let text

    if (priceStatus === 0) {
      text = 'remain'
    } else if (priceStatus > 0) {
      text = 'increase'
    } else {
      text = 'decrease'
    }
    return text
  }

  const Body = () => {
    const [apiResponse, setFuelPrice] = useState(null)
  
    // useEffect(() => {
    //   // Fetch the last row of fuel prices from the Laravel API
    //   axios
    //     .get('https://api.hargapetrol.com.my/api/petrol-homepage') // Replace with your actual API endpoint
    //     .then(response => setFuelPrice(response.data))
    //     .catch(error => console.error('Error fetching fuel price:', error))
    // }, [])
  
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0'); // Pad single digit day with 0
    const currentDate = `${year}${month}${day}`; // Correct string concatenation
  
    var currentURLWithoutPath = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

    if(currentURLWithoutPath.includes("bitcare.me"))
    {
      currentURLWithoutPath += "/hargapetrol";
    }

    useEffect(() => {
      fetch(currentURLWithoutPath+`/data/${currentDate}.json`) // Assuming your .json file is in the public directory
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Convert the response to JSON
        })
        .then(data => setFuelPrice(data)) // Set the fetched data to state
        .catch(error => console.error('Error fetching fuel price:', error));
    }, [currentDate]); // Include currentDate as a dependency  
    
    if (!apiResponse) {
      return <div>Retrieving the current petrol price <img src="../assets/loading.gif" width="17px" alt="loading" /></div>
    }

    const fuelPrice = apiResponse.LastFuelPrice.original;

    const iconRon97 = arrowDisplay(fuelPrice.ron97, fuelPrice.previousRon97)
    const iconRon95 = arrowDisplay(fuelPrice.ron95, fuelPrice.previousRon95)
    const iconDiesel = arrowDisplay(fuelPrice.diesel, fuelPrice.previousDiesel)
    const iconDiesel_eastmsia = arrowDisplay(fuelPrice.diesel_eastmsia, fuelPrice.previousDiesel_eastmsia)

    const txtRon97 = textDisplay(fuelPrice.ron97, fuelPrice.previousRon97)
    const txtRon95 = textDisplay(fuelPrice.ron95, fuelPrice.previousRon95)
    const txtDiesel = textDisplay(fuelPrice.diesel, fuelPrice.previousDiesel)
    const txtDiesel_eastmsia = textDisplay(fuelPrice.diesel_eastmsia, fuelPrice.previousDiesel_eastmsia)

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h4 font-weight-bold'>
                Current Petrol Price RON95 RON97 Diesel
              </h2>
              <p className='card-text'>
                The information below is the Retail Price of Petroleum Products for the Period&nbsp;
                <b>{fuelPrice.date}</b>&nbsp; To&nbsp;
                <b>{fuelPrice.date_to}</b>&nbsp; announced by the Malaysian Ministry of Finance (MOF) and KPDNHEP:
              </p>
              <div>
                <small className='d-block'>
                  <a className='text-muted' href='./author.html'>
                    Source: Kewangan Malaysia (MOF) and KPDNHE
                  </a>
                </small>
              </div>
              <br/>
            </div>
          </div>


          <div className='row'>
            <div className='col-lg-6'>
              <div className='mb-3 d-flex align-items-center'>
                <StyledWrapper>
                  <div className="e-card playing">
                    <div className="image"/>

                    <div className="wave ron97"/>
                    <div className="wave ron97"/>
                    <div className="wave ron97"/>

                    <div className="infotop">
                      <FontAwesomeIcon icon={faGasPump}/>
                      &nbsp; RON97
                      <p className='small'>
                        ( All over Malaysia )
                      </p>
                      <div
                        className="infotopSub"
                        style={{
                        color: iconRon97.color
                      }}>
                        <FontAwesomeIcon icon={iconRon97.icon}/>
                        &nbsp; RM {(fuelPrice.ron97 - fuelPrice.previousRon97).toFixed(2)}
                      </div>
                      <br/>
                      RM{fuelPrice.ron97}
                      /<small class='small'>Per liter</small>
                      <p className='old-price small'>RM{fuelPrice.previousRon97}</p>
                    </div>
                  </div>
                </StyledWrapper>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='mb-3 d-flex align-items-center'>
                <StyledWrapper>
                  <div className="e-card playing">
                    <div className="image"/>

                    <div className="wave ron95"/>
                    <div className="wave ron95"/>
                    <div className="wave ron95"/>

                    <div className="infotop text-color-black">
                      <FontAwesomeIcon icon={faGasPump}/>
                      &nbsp; RON95
                      <p className='small'>
                        ( All over Malaysia )
                      </p>
                      <div
                        className="infotopSub"
                        style={{
                        color: iconRon95.color
                      }}>
                        <FontAwesomeIcon icon={iconRon95.icon}/>
                        &nbsp; RM {(fuelPrice.ron95 - fuelPrice.previousRon95).toFixed(2)}
                      </div>
                      <br/>
                      RM{fuelPrice.ron95}
                      /<small class='small'>Per liter</small>
                      <p className='old-price small'>RM{fuelPrice.previousRon95}</p>
                    </div>
                  </div>
                </StyledWrapper>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6'>
              <div className='mb-3 d-flex align-items-center'>
                <StyledWrapper>
                  <div className="e-card playing">
                    <div className="image"/>

                    <div className="wave diesel"/>
                    <div className="wave diesel"/>
                    <div className="wave diesel"/>

                    <div className="infotop">
                      <FontAwesomeIcon icon={faGasPump}/>
                      &nbsp; Diesel
                      <p className='small'>
                        ( Peninsular Malaysia )
                      </p>
                      <div
                        className="infotopSub"
                        style={{
                        color: iconDiesel.color
                      }}>
                        <FontAwesomeIcon icon={iconDiesel.icon}/>
                        &nbsp; RM {(fuelPrice.diesel - fuelPrice.previousDiesel).toFixed(2)}
                      </div>
                      <br/>
                      RM{fuelPrice.diesel}
                      /<small class='small'>Per liter</small>
                      <p className='old-price small'>RM{fuelPrice.previousDiesel}</p>
                    </div>
                  </div>
                </StyledWrapper>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='mb-3 d-flex align-items-center'>
                <StyledWrapper>
                  <div className="e-card playing">
                    <div className="image"/>

                    <div className="wave diesel_eastmsia"/>
                    <div className="wave diesel_eastmsia"/>
                    <div className="wave diesel_eastmsia"/>

                    <div className="infotop">
                      <FontAwesomeIcon icon={faGasPump}/>
                      &nbsp; Diesel
                      <p className='small'>
                        ( Sabah, Sarawak & Labuan )
                      </p>
                      <div
                        className="infotopSub"
                        style={{
                        color: iconDiesel_eastmsia.color
                      }}>
                        <FontAwesomeIcon icon={iconDiesel_eastmsia.icon}/>
                        &nbsp; RM {(fuelPrice.diesel_eastmsia - fuelPrice.previousDiesel_eastmsia).toFixed(2)}
                      </div>
                      <br/>
                      RM{fuelPrice.diesel_eastmsia}
                      /<small class='small'>Per liter</small>
                      <p className='old-price small'>RM{fuelPrice.previousDiesel_eastmsia}</p>
                    </div>
                  </div>
                </StyledWrapper>
              </div>
            </div>
          </div>


          <div className='row'>
            <div className='col-lg-12'>
              <p className='card-text'>
              The following is the setting of the retail price of petroleum products based on the Automatic Pricing Mechanism formula calculated on a weekly basis:
              </p>
              1. Retail price of petrol&nbsp;
              <b>RON95</b>&nbsp;
              {txtRon95}&nbsp;
              on the level{' '}&nbsp;
              <b>RM{fuelPrice.ron95}</b>&nbsp;
              per liter;
              <br/>
              2. Retail price of petrol&nbsp;
              <b>RON97</b>&nbsp;
              {txtRon97}&nbsp;
              on the level{' '}&nbsp;
              <b>RM{fuelPrice.ron97}</b>&nbsp;
              per liter;
              <br/>
              3. Retail price&nbsp;
              <b>diesel</b>&nbsp;
              {txtDiesel}&nbsp;
              on the level{' '}&nbsp;
              <b>RM{fuelPrice.diesel}</b>&nbsp;
              per liter in Peninsular Malaysia
              <br/>
              4. Retail price&nbsp;
              <b>diesel</b>&nbsp;
              {txtDiesel_eastmsia}&nbsp;
              on the level{' '}&nbsp;
              <b>RM{fuelPrice.diesel_eastmsia}</b>&nbsp;
              in Sabah, Sarawak & Labuan.
              <br/>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-12'>
              <div
                className='container'
                style={{
                paddingTop: 25
              }}>
                <div
                  className='row'
                  style={{
                  backgroundColor: 'white'
                }}>

                </div>
              </div>
            </div>
          </div>

          <ChartPrice apiResponse={apiResponse}/>

          <FAQ/>
        </div>
      </div>
    )
  }

  const StyledWrapper = styled.div `
  .e-card {
    background: transparent;
    box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
    position: relative;
    width: 340px;
    height: 300px;
    border-radius: 16px;
    overflow: hidden;
    letter-spacing: 2px;
  }
  
  .wave {
    position: absolute;
    width: 540px;
    height: 700px;
    opacity: 0.6;
    left: 0;
    top: 0;
    margin-left: -50%;
    margin-top: -70%;
  }
  
  .ron97 {
    background: linear-gradient(744deg,#16d200,#14bb00 60%,#109900);
  }
  
  .ron95 {
    background: linear-gradient(744deg,#FFC300,#ffd036 60%,#ffda62);
  }
  
  .diesel {
    background: linear-gradient(744deg,#272727,#616161 60%,#838383);
  }
  
  .diesel_eastmsia {
    background: linear-gradient(744deg,#000000,#292929 60%,#505050);
  }
  
  .icon {
    width: 3em;
    margin-top: -1em;
    padding-bottom: 1em;
  }
  
  .infotop {
    text-align: center;
    font-size: 25px;
    position: absolute;
    top: 1.3em;
    left: 0;
    right: 0;
    color: rgb(255, 255, 255);
    font-weight: 800;
  }
  
  .text-color-black{
    color: rgb(0, 0, 0);
  }
  
  .infotopSub {
    background-color:white;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 19px;
  }
  
  .name {
    font-size: 14px;
    font-weight: 100;
    position: relative;
    top: 1em;
    text-transform: lowercase;
  }
  
  .wave:nth-child(2),
  .wave:nth-child(3) {
    top: 210px;
  }
  
  .playing .wave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
  }
  
  .wave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
  }
  
  .playing .wave:nth-child(2) {
    animation-duration: 4000ms;
  }
  
  .wave:nth-child(2) {
    animation-duration: 50s;
  }
  
  .playing .wave:nth-child(3) {
    animation-duration: 5000ms;
  }
  
  .wave:nth-child(3) {
    animation-duration: 45s;
  }
  
  .small{
    font-size: 15px;
    font-weight: 'normal';
  }
  
  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
      .e-card {
        width: 320px;
      }
    }

    @media only screen and (max-width: 1024px) {
      .e-card {
        width: 300px;
      }
    }
  }
  `;
  export default Body
